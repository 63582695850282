<template lang="html">
    <img v-if="image" v-bind="image" :alt="alt" class="" />
</template>

<script>
export default {
    name: 'DynamicImage',

    props: {
        imageName: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            required: false,
            default: '',
        },
    },

    data: () => ({
        image: null,
    }),
    created() {
        this.importImage(this.imageName).then(image => {
            this.image = image
        })
    },

    methods: {
        importImage: async imageName => {
            const {
                default: dataUri, // url loader
                src,
                srcSet,
                // width,
                // height,
            } = await import(
                /* webpackMode: 'eager' */ `assets/images/${imageName}?resize`
            )

            return {
                src: src || dataUri,
                srcSet,
                // width, // TODO: update css to allow setting dimensions
                // height,
            }
        },
    },
}
</script>
